import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const SettingsPage = () => {
    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Search Results Page
            </Typography>
            <Typography variant="body1">
                This is the Search Results page
            </Typography>
        </Box>
    );
};

export default SettingsPage;
